import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { getCSVExport } from '../../../services/getCSVExport/getCSVExport';
import { getCSVResponsesExport } from '../../../services/getCSVResponsesExport/getCSVResponsesExport';
import ExportCSVItem from '../../Atoms/ExportCSVItem/ExportCSVItem';
import Modal from '../../Molecules/Modal/Modal';
import { ExportCSVModalProps } from './ExportCSVModal.model';
import { Heading } from '../../Atoms/Heading/Heading';

function ExportCSVModal({
  isExportCSVModalOpen,
  toggleExportCSVModal,
}: ExportCSVModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const surveyResultsDownload = useCallback(
    (year?: number) => {
      const makeRequestsAsync = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: '',
          });

          getCSVExport({ year }, accessToken).then(() => {
            toggleExportCSVModal();
          });
        } catch (e) {
          alert('Authentication Error');
        }
      };
      makeRequestsAsync();
    },
    [getAccessTokenSilently, toggleExportCSVModal]
  );

  const surveyResponsesDownload = useCallback(() => {
    const makeRequestsAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });

        getCSVResponsesExport(accessToken, {
          questions: [],
        }).then(() => {
          toggleExportCSVModal();
        });
      } catch (e) {
        alert('Authentication Error');
      }
    };
    makeRequestsAsync();
  }, [getAccessTokenSilently, toggleExportCSVModal]);

  return (
    <Modal
      isOpen={isExportCSVModalOpen}
      title="Export Data"
      toggleModal={toggleExportCSVModal}
    >
      <div className="grid grid-cols-1 gap-2">
        <Heading level="h3" textStyle="Sub Heading 3">
          Survey Respondents
        </Heading>
        <ExportCSVItem
          fileName="Survey All Respondents 2023"
          onClick={() => {
            surveyResultsDownload(2023);
          }}
        />

        <ExportCSVItem
          fileName="Survey All Respondents 2024"
          onClick={() => {
            surveyResultsDownload(2024);
          }}
        />

        <Heading level="h3" textStyle="Sub Heading 3">
          Survey Results
        </Heading>
        <ExportCSVItem
          fileName="Survey Summary YoY"
          onClick={() => {
            surveyResponsesDownload();
          }}
        />
      </div>
    </Modal>
  );
}

export default ExportCSVModal;
