import React, { useEffect, useRef, useState } from 'react';
import { exportChartToSvg } from '../../../helpers/triggerFileDownload/triggerFileDownload';
import { Button } from '../../Atoms/Button/Button';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import NoResult from '../NoResult/NoResult';
import { EtraderChannelsTableProps } from './EtraderChannelsTable.model';

function EtraderChannelsTable({
  results,
  SVGExportName,
  onCSVDownload,
}: EtraderChannelsTableProps) {
  const [platforms, setPlatforms] = useState<string[]>([]);
  const chartRef = useRef<null | HTMLTableElement>(null);

  useEffect(() => {
    setPlatforms(Array.from(new Set(results.map((result) => result.answer))));
  }, [results]);

  const exportCsv = () => {
    var csv = [];

    const headerRow = [
      'Products Traded',
      '2023 (Actual)',
      '2024 (Predicted)',
      '2024 (Actual)',
      '2025 (Predicted)',
      '% Increase (2024 Actual-2025 Precdicted)',
    ];

    csv.push(headerRow.join(','));

    for (const platform of platforms) {
      // 2023 actual results given in 2023
      const year2023 = results.find(
        (result) =>
          result.answer === platform &&
          result.year === 2023 &&
          result.questionId.includes('2023')
      );
      // 2024 predicted results given in 2023
      const year2024predicted = results.find(
        (result) =>
          result.answer === platform &&
          result.year === 2023 &&
          result.questionId.includes('2024')
      );
      // 2024 actual results given in 2024
      const year2024 = results.find(
        (result) =>
          result.answer === platform &&
          result.year === 2024 &&
          result.questionId.includes('2024')
      );
      // 2025 predicted results given in 2024
      const year2025 = results.find(
        (result) =>
          result.answer === platform &&
          result.year === 2024 &&
          result.questionId.includes('2025')
      );

      const row = [
        platform,
        year2023 ? Math.round(year2023.average!) + '%' : 'N/A',
        year2024predicted
          ? Math.round(year2024predicted.average!) + '%'
          : 'N/A',
        year2024 ? Math.round(year2024.average!) + '%' : 'N/A',
        year2025 ? Math.round(year2025.average!) + '%' : 'N/A',
        year2024 && year2025
          ? Math.round(year2025.average!) - Math.round(year2024.average!) + '%'
          : 'N/A',
      ];

      csv.push(row.join(','));
    }

    var csvFile;
    var downloadLink;

    if (
      window.Blob === undefined ||
      window.URL === undefined ||
      window.URL.createObjectURL === undefined
    ) {
      alert("Your browser doesn't support Blobs");
      return;
    }

    const currentDate = new Date();
    const datePrefix = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

    const filename = `Survey_Summary_YoY-${datePrefix}`;

    csvFile = new Blob([csv.join('\n')], { type: 'text/csv' });
    downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <div className="w-full overflow-hidden bg-limestone p-4 lg:px-12 lg:py-8">
      <div className="flex w-full flex-row flex-wrap items-start justify-between">
        <div className="w-full md:w-[50%] lg:w-[100%] xl:w-[60%]">
          <Heading level="h4" className="mb-2 xl:mb-4">
            What percentage of your trading is and will be through e-Trading
            channels? This includes API, multi-dealer platforms and
            single-dealer platforms.
          </Heading>
          <Paragraph variant="sm" className="mb-6 lg:mb-8">
            Respondents selected a percentage for each response.
          </Paragraph>
        </div>
        <div className="flex w-80 gap-x-4 lg:w-80">
          {SVGExportName && (
            <Button
              variant="secondary"
              className="mb-6 w-1/2 lg:w-auto xl:mb-0"
              type="button"
              onClick={() => {
                exportChartToSvg(SVGExportName || 'chart', chartRef);
              }}
            >
              Download SVG
            </Button>
          )}
          <Button
            variant="standard"
            className="mb-6 w-1/2 lg:w-auto xl:mb-0"
            type="button"
            onClick={exportCsv}
          >
            Download CSV
          </Button>
        </div>
      </div>
      {results.length > 0 ? (
        <div className="mt-4 w-full max-w-full overflow-hidden xl:mt-0">
          <div className="relative w-auto max-w-full overflow-auto">
            <table
              ref={chartRef}
              className="w-full table-auto text-center font-normal text-gray-500"
            >
              <thead className="bg-gray-50  uppercase  text-white">
                <tr>
                  <th scope="col" className="bg-limestone py-6 px-6"></th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    2023 (Actual)
                  </th>

                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    2024 (Actual)
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    2025 (Predicted)
                  </th>
                  <th
                    scope="col"
                    className="border border-titanium bg-blue-900 py-6 px-6"
                  >
                    % increase
                    <br />
                    (2024-2025)
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-titanium">
                {
                  //TODO: Finish this chart
                }
                {platforms.map((platform, platformIndex) => {
                  // 2023 actual results given in 2023
                  const year2023 = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === 2023 &&
                      result.questionId.includes('2023')
                  );
                  // 2024 actual results given in 2024
                  const year2024 = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === 2024 &&
                      result.questionId.includes('2024')
                  );
                  // 2025 predicted results given in 2024
                  const year2025 = results.find(
                    (result) =>
                      result.answer === platform &&
                      result.year === 2024 &&
                      result.questionId.includes('2025')
                  );

                  return (
                    <tr className="border-b bg-white" key={platformIndex}>
                      <td className="whitespace-nowrap border border-titanium py-6 px-6 text-lg font-medium text-gray-900">
                        {platform}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {year2023 ? Math.round(year2023.average!) + '%' : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {year2024 ? Math.round(year2024.average!) + '%' : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {year2025 ? Math.round(year2025.average!) + '%' : 'N/A'}
                      </td>
                      <td className="border border-titanium py-4 px-6">
                        {year2024 && year2025
                          ? Math.round(year2025.average!) -
                            Math.round(year2024.average!) +
                            '%'
                          : 'N/A'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
}

export default EtraderChannelsTable;
