import { DeepPartial } from '@reduxjs/toolkit';
import {
  BubbleDataPoint,
  ChartDatasetProperties,
  ChartType,
  ChartTypeRegistry,
  DefaultDataPoint,
  ScatterDataPoint,
} from 'chart.js';

interface Data {
  answer: string;
  average?: number;
  complete: number;
  abandoned: number;
  year: number;
}

interface IChartData {
  Answer: string;
  TwentyTwentyThree: number;
  TwentyTwentyFour: number;
  TwentyTwentyThreeAbandoned: number;
  TwentyTwentyFourAbandoned: number;
}

interface IDatasets {
  twentyTwentyThreeTotal: number;
  twentyTwentyFourTotal: number;
  datasets: ChartDatasetExtended<
    'bar',
    (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[]
  >[];
  labels: string[];
}

export type ChartDatasetExtended<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>
> = DeepPartial<
  {
    [key in ChartType]: {
      type: key;
    } & ChartTypeRegistry[key]['datasetOptions'];
  }[TType]
> &
  ChartDatasetProperties<TType, TData> & {
    countData: any;
  };

export function getSurveyDistributionDatasets(
  data: Data[],
  twentyTwentyThreeFilter: boolean,
  twentyTwentyFourFilter: boolean
): IDatasets {
  // Group Data
  let groupedData: IChartData[] = [];
  for (const d of data) {
    // Check if answer already exists in the data
    let answer = groupedData.find((x) => x.Answer === d.answer);
    if (!answer) {
      answer = {
        Answer: d.answer,
        TwentyTwentyThree: 0,
        TwentyTwentyFour: 0,
        TwentyTwentyThreeAbandoned: 0,
        TwentyTwentyFourAbandoned: 0,
      };
      groupedData.push(answer);
    }

    if (d.year === 2023) {
      answer.TwentyTwentyThree = d.complete;
      answer.TwentyTwentyThreeAbandoned = d.abandoned;
    }
    if (d.year === 2024) {
      answer.TwentyTwentyFour = d.complete;
      answer.TwentyTwentyFourAbandoned = d.abandoned;
    }
  }

  // Sort Data
  let sortedData = [...groupedData].sort((prev, next) => {
    // Order by 2024 Data
    if (prev.TwentyTwentyFour < next.TwentyTwentyFour) return 1;
    if (prev.TwentyTwentyFour > next.TwentyTwentyFour) return -1;

    // Then order by 2023 Data
    if (prev.TwentyTwentyThree < next.TwentyTwentyThree) return 1;
    if (prev.TwentyTwentyThree > next.TwentyTwentyThree) return -1;
    return 0;
  });

  // Get totals for year
  const twentyTwentyThreeTotal = sortedData.reduce(
    (acc, result) =>
      acc + result.TwentyTwentyThree + result.TwentyTwentyThreeAbandoned,
    0
  );
  const twentyTwentyFourTotal = sortedData.reduce(
    (acc, result) =>
      acc + result.TwentyTwentyFour + result.TwentyTwentyFourAbandoned,
    0
  );

  let datasets: ChartDatasetExtended<
    'bar',
    (number | [number, number] | ScatterDataPoint | BubbleDataPoint | null)[]
  >[] = [];

  // Only add data if filter checked
  if (twentyTwentyThreeFilter && twentyTwentyThreeTotal > 0) {
    const twentyTwentyThreeData = sortedData.map((result) =>
      Math.round((result.TwentyTwentyThree / twentyTwentyThreeTotal) * 100)
    );
    const twentyTwentyThreeCountData = sortedData.map(
      (result) => result.TwentyTwentyThree
    );
    if (twentyTwentyThreeData.length > 0) {
      datasets.push({
        data: twentyTwentyThreeData,
        countData: twentyTwentyThreeCountData,
        label: '2023 Complete as a % of 2023 total respondents',
        backgroundColor: ['#2B9AB3'],
        stack: '2023',
      });
    }

    const twentyTwentyThreeDataAbandoned = sortedData.map((result) =>
      Math.round(
        (result.TwentyTwentyThreeAbandoned / twentyTwentyThreeTotal) * 100
      )
    );
    const twentyTwentyThreeCountDataAbandoned = sortedData.map(
      (result) => result.TwentyTwentyThreeAbandoned
    );
    if (twentyTwentyThreeData.length > 0) {
      datasets.push({
        data: twentyTwentyThreeDataAbandoned,
        countData: twentyTwentyThreeCountDataAbandoned,
        label: '2023 Abandoned as a % of 2023 total respondents',
        backgroundColor: ['#E2E4E5'],
        stack: '2023',
      });
    }
  }

  // Only add data if filter checked
  if (twentyTwentyFourFilter && twentyTwentyFourTotal > 0) {
    const twentyTwentyFourData = sortedData.map((result) =>
      Math.round((result.TwentyTwentyFour / twentyTwentyFourTotal) * 100)
    );
    const twentyTwentyFourCountData = sortedData.map(
      (result) => result.TwentyTwentyFour
    );
    if (twentyTwentyFourData.length > 0) {
      datasets.push({
        data: twentyTwentyFourData,
        countData: twentyTwentyFourCountData,
        label: '2024 Complete as a % of 2024 total respondents',
        backgroundColor: ['#E26E00'],
        stack: '2024',
      });
    }

    const twentyTwentyFourDataAbandoned = sortedData.map((result) =>
      Math.round(
        (result.TwentyTwentyFourAbandoned / twentyTwentyFourTotal) * 100
      )
    );
    const twentyTwentyFourCountDataAbandoned = sortedData.map(
      (result) => result.TwentyTwentyFourAbandoned
    );
    if (twentyTwentyFourData.length > 0) {
      datasets.push({
        data: twentyTwentyFourDataAbandoned,
        countData: twentyTwentyFourCountDataAbandoned,
        label: '2024 Abandoned as a % of 2024 total respondents',
        backgroundColor: ['#E2E4E5'],
        stack: '2024',
      });
    }
  }

  const labels = sortedData.map((item) => {
    return item.Answer;
  });

  return {
    twentyTwentyThreeTotal,
    twentyTwentyFourTotal,
    datasets,
    labels,
  };
}
