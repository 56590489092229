import React, { memo, useEffect, useRef, useState } from 'react';
import NoResult from '../NoResult/NoResult';
import ChartRankButtons from '../ChartRankButtons/ChartRankButtons';
import ReactEcharts from 'echarts-for-react';
import { VerticalChartProps } from './VerticalChart.model';
import { YearCheckboxs } from '../../Atoms/YearCheckboxs/YearCheckboxs';
import {
  ChartDatasetExtended,
  getDatasets,
} from '../../../features/getDatasets/getDatasets';
import { formatLabels } from '../../../helpers/formatLabels/formatLabels';
import { exportEChartAsSvg } from '../../../helpers/triggerFileDownload/triggerFileDownload';
import ChartDownloadButtons from '../../Molecules/ChartDownloadButtons/ChartDownloadButtons';
import ChartHeading from '../../Molecules/ChartHeading/ChartHeading';

const VerticalChart = memo(
  ({
    data,
    verticalChartTitle,
    verticalChartDescription: verticalChartdescription,
    bottomTitle,
    chartRankButtons,
    activeChartButton,
    setActiveChartButton,
    className = '',
    rangeType = 'Rank',
    SVGExportName,
    onCSVDownload,
    onXLSDownload,
    footerText,
  }: VerticalChartProps) => {
    const [twentyTwentyThreeTotal, setTwentyTwentyThreeTotal] =
      useState<number>(0);
    const [twentyTwentyFourTotal, setTwentyTwentyFourTotal] =
      useState<number>(0);

    const [twentyTwentyThreeFilter, setTwentyTwentyThreeFilter] =
      useState<boolean>(false);
    const [twentyTwentyFourFilter, setTwentyTwentyFourFilter] =
      useState<boolean>(true);

    const [labels, setLabels] = useState<string[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const echartsRef = useRef<InstanceType<typeof ReactEcharts>>(null);

    useEffect(() => {
      const res = getDatasets(
        data,
        twentyTwentyThreeFilter,
        twentyTwentyFourFilter
      );

      setTwentyTwentyThreeTotal(res.twentyTwentyThreeTotal);
      setTwentyTwentyFourTotal(res.twentyTwentyFourTotal);
      setLabels(res.labels);
      setChartData(res.datasets);
    }, [data, twentyTwentyThreeFilter, twentyTwentyFourFilter]);

    // Define the tooltip data
    let toolTipCountData: string[][] = [];

    // Load the tooltip data
    chartData.map((obj, index) => {
      let x = obj as ChartDatasetExtended;
      toolTipCountData[index] = x.countData.toString().split(',');
      return toolTipCountData[index];
    });

    return (
      <div
        className={`p-4 lg:px-12 lg:pt-8 ${
          chartRankButtons && 'pb-0'
        } ${className}`}
      >
        <div className="flex w-full flex-row flex-wrap items-start justify-between">
          <ChartHeading
            chartTitle={verticalChartTitle}
            chartDescription={verticalChartdescription}
          />
          <ChartDownloadButtons
            onCSVDownload={onCSVDownload}
            onXLSDownload={onXLSDownload}
            onSVGDownload={() => {
              exportEChartAsSvg(SVGExportName || 'chart', echartsRef);
            }}
          />
        </div>
        {data.length > 0 ? (
          <>
            <YearCheckboxs
              twentyTwentyThreeChecked={
                twentyTwentyThreeFilter && twentyTwentyThreeTotal > 0
              }
              twentyTwentyFourChecked={
                twentyTwentyFourFilter && twentyTwentyFourTotal > 0
              }
              twentyTwentyThreeEnabled={twentyTwentyThreeTotal > 0}
              twentyTwentyFourEnabled={twentyTwentyFourTotal > 0}
              onTwentyTwentyThreeChange={(checked: boolean) => {
                setTwentyTwentyThreeFilter(checked);
              }}
              onTwentyTwentyFourChange={(checked: boolean) => {
                setTwentyTwentyFourFilter(checked);
              }}
            />

            <ReactEcharts
              ref={echartsRef}
              style={{ height: '609px' }}
              opts={{ renderer: 'svg' }}
              notMerge={true}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow',
                  },
                  backgroundColor: 'black',
                  textStyle: {
                    color: '#fff',
                  },
                  formatter: function (params: any) {
                    let thisToolTip = params[0].name + '<br>';
                    params.forEach((param: any, index: number) => {
                      thisToolTip =
                        thisToolTip +
                        ' ' +
                        params[index].marker +
                        params[index].seriesName +
                        ' <strong>' +
                        params[index].value +
                        '</strong><br>';
                      thisToolTip =
                        thisToolTip +
                        'Respondents: ' +
                        toolTipCountData[index][param.dataIndex] +
                        '<br>';
                    });
                    return thisToolTip;
                  },
                },
                toolbox: {},
                xAxis: {
                  type: 'category',
                  data: formatLabels(labels),
                },
                yAxis: {
                  type: 'value',
                  min: 0,
                  max: 100,
                  axisLabel: {
                    formatter: '{value}%',
                  },
                },
                title: {
                  text: '% of Respondents',
                },
                series: chartData.map((obj) => {
                  return {
                    name: obj.label,
                    type: 'bar',
                    data: obj.data,
                    label: {
                      show: true,
                      position: 'insideBottom',
                      distance: 20,
                      color: 'black',
                      formatter: function (d: any) {
                        return d.data + '%';
                      },
                    },
                    color: obj.backgroundColor,
                  };
                }),
              }}
            />

            {chartRankButtons && (
              <ChartRankButtons
                activeChartButton={activeChartButton}
                setActiveChartButton={setActiveChartButton}
                rangeType={rangeType}
              />
            )}
          </>
        ) : (
          <NoResult />
        )}
        <div className="py-5">{footerText}</div>
      </div>
    );
  }
);

VerticalChart.displayName = 'VerticalChart';

export default VerticalChart;
