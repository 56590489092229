import { triggerFileDownload } from '../../helpers/triggerFileDownload/triggerFileDownload';
import { api } from '../axiosInstance';

export interface GetCSVResponseExportParams {
  questions: string[];
  source?: string[];
  country?: string[];
  age?: string[];
  tradedProducts?: string[];
}

export const getCSVResponsesExport = async (
  token: string,
  params: GetCSVResponseExportParams
) => {
  new Promise((resolve, reject) => {
    // Make the request
    api
      .get<File>('CSVAggregatedResponsesExport', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        // Set Prefix
        const currentDate = new Date();
        const datePrefix = `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()}`;

        const filename =
          params.questions?.length > 0
            ? `Survey_Summary_YoY_${params.questions[0]}_${datePrefix}`
            : `Survey_Summary_YoY-${datePrefix}`;

        // Download and resolve.
        triggerFileDownload(res, filename);
        resolve(res);
      })
      .catch((error) => {
        // Alert and reject.
        alert('Failed to download CSV export, please try again later.');
        reject(error);
      });
  });
};
