// Dependencies
import { FunctionComponent } from 'react';
import { Checkbox } from '../../Molecules/Forms/Checkbox/Checkbox';

// Models
export interface IYearCheckboxsProps {
  twentyTwentyThreeChecked: boolean;
  twentyTwentyFourChecked: boolean;
  twentyTwentyThreeEnabled?: boolean;
  twentyTwentyFourEnabled?: boolean;
  onTwentyTwentyThreeChange?: (checked: boolean) => void;
  onTwentyTwentyFourChange?: (checked: boolean) => void;
}

export const YearCheckboxs: FunctionComponent<IYearCheckboxsProps> = ({
  twentyTwentyThreeChecked,
  twentyTwentyFourChecked,
  twentyTwentyThreeEnabled = true,
  twentyTwentyFourEnabled = true,
  onTwentyTwentyThreeChange,
  onTwentyTwentyFourChange,
}) => {
  return (
    <div className="flex w-full flex-col items-end justify-center pt-6 pb-2 lg:pt-3">
      <div className={`columns-2`}>
        <div className={`block w-full`}>
          <Checkbox
            label="2023"
            name="2023"
            labelClasses={[
              `text-white px-1 ${
                twentyTwentyThreeEnabled ? 'bg-topaz-400' : 'bg-titanium'
              }`,
            ]}
            onChange={() => {
              onTwentyTwentyThreeChange &&
                onTwentyTwentyThreeChange(!twentyTwentyThreeChecked);
            }}
            value="2023"
            checked={twentyTwentyThreeChecked}
            onBlur={() => {}}
            disabled={!twentyTwentyThreeEnabled || !twentyTwentyFourChecked}
          />
        </div>
        <div className={`mt-3 block w-full`}>
          <Checkbox
            label="2024"
            name="2024"
            labelClasses={[
              `text-white px-1 ${
                twentyTwentyFourEnabled ? 'bg-clementine-400' : 'bg-titanium'
              }`,
            ]}
            onChange={() => {
              onTwentyTwentyFourChange &&
                onTwentyTwentyFourChange(!twentyTwentyFourChecked);
            }}
            value="2024"
            checked={twentyTwentyFourChecked}
            onBlur={() => {}}
            disabled={!twentyTwentyFourEnabled || !twentyTwentyThreeChecked}
          />
        </div>
      </div>
    </div>
  );
};
