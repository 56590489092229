import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getSurveyResultsStatistics } from '../../../services/surveyResultStatistics/getSurveyResultStatistics';
import SummaryResultsItem from '../../Atoms/SummaryResulsItem/SummaryResultsItem';

function SurveyDistributionSummary() {
  const { getAccessTokenSilently } = useAuth0();

  const [numOfCompletes, setNumOfCompletes] = useState<number>(0);
  const [percentageOnMobile, setPercentageOnMobile] = useState<number>();
  const [responsesThisWeek, setResponsesThisWeek] = useState<number>(0);
  const [averageTimeToComplete, setAverageTimeToComplete] = useState<number>(0);
  const [totalClicks, setTotalClicks] = useState<number>(0);
  const [numOfDropOffs, setNumOfDropOffs] = useState<number>(0);
  const [dropOffRate, setDropOffRate] = useState<number>(0);
  const [questionWithHighestDropOffRate, setQuestionWithHighestDropOffRate] =
    useState<string>('---');

  useEffect(() => {
    const makeRequestsAsync = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: '',
        });
        getSurveyResultsStatistics({ year: 2024 }, accessToken).then((res) => {
          setNumOfCompletes(res.totalComplete);
          setPercentageOnMobile(res.percentOnMobile);
          setResponsesThisWeek(res.totalCompleteInLast7Days);
          setAverageTimeToComplete(
            Number((res.averageCompleteTimeInSeconds / 60).toFixed(1))
          );
          setTotalClicks(res.totalSurveyOpens);
          setQuestionWithHighestDropOffRate(
            `${res.highestDropOffQuestionNumber}`
          );

          // Drop Offs
          const dropOffsCount = res.totalSurveyOpens - res.totalComplete;
          setNumOfDropOffs(dropOffsCount);
          setDropOffRate(
            100 - Math.round((100 / res.totalSurveyOpens) * res.totalComplete)
          );
        });
      } catch (e) {
        alert('Authentication Error');
      }
    };
    makeRequestsAsync();
  }, [getAccessTokenSilently]);

  return (
    <div className="mb-8 grid w-full grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-0">
      <SummaryResultsItem
        className=" border-charcoal last:border-r-0 lg:border-r lg:after:absolute lg:after:bottom-[-15px] lg:after:left-[45%] lg:after:block lg:after:h-px lg:after:w-11/12 lg:after:-translate-x-1/2 lg:after:bg-charcoal lg:after:content-['']"
        description={'Total number of completes'}
        result={numOfCompletes}
        suffix={''}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r lg:after:absolute lg:after:bottom-[-15px] lg:after:left-[45%] lg:after:block lg:after:h-px lg:after:w-11/12 lg:after:-translate-x-1/2 lg:after:bg-charcoal lg:after:content-[''] 2xl:pl-6"
        description={'Percentage on mobile'}
        result={percentageOnMobile}
        suffix="%"
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r lg:after:absolute lg:after:bottom-[-15px] lg:after:left-[45%] lg:after:block lg:after:h-px lg:after:w-11/12 lg:after:-translate-x-1/2 lg:after:bg-charcoal lg:after:content-[''] 2xl:pl-6"
        description={'Responses this week'}
        result={responsesThisWeek}
        suffix={''}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r lg:after:absolute lg:after:bottom-[-15px] lg:after:left-[45%] lg:after:block lg:after:h-px lg:after:w-11/12 lg:after:-translate-x-1/2 lg:after:bg-charcoal lg:after:content-[''] 2xl:pl-6"
        description={'Average time to complete in minutes'}
        result={averageTimeToComplete}
        suffix={''}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r"
        description={'Total clicks / survey opens'}
        result={totalClicks}
        suffix={''}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r 2xl:pl-6"
        description={'No. of drop offs'}
        result={numOfDropOffs}
        suffix={''}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r 2xl:pl-6"
        description={'Drop off rate'}
        result={dropOffRate}
        suffix={'%'}
      />

      <SummaryResultsItem
        className="border-charcoal last:border-r-0 lg:border-r 2xl:pl-6"
        description={'Question with highest drop off rate'}
        dataInverted={true}
        result={parseInt(questionWithHighestDropOffRate)}
        suffix={`Q`}
      />
    </div>
  );
}

export default SurveyDistributionSummary;
